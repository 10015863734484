import { Edition } from ".";

export const store  = {
  responsibility: [
    'presidente',
    'vice-presidente',
    'tesouraria',
    'esportes',
    'secretaria',
    'alojamento',
    'eventos',
  ],
  editions: {
    JF: [
      {
        label: 'JF 2022',
        value: Edition.JOIA_2022
      },
      {
        label: 'JF 2023',
        value: Edition.JOIA_2023
      },
      {
        label: 'Verão 2023',
        value: Edition.VERAO_2023
      },
      {
        label: 'E-Sports 2023',
        value: Edition.ESPORTS_2023
      },
      {
        label: 'JF 2024',
        value: Edition.JOIA_2024
      },
      {
        label: 'Verão 2024',
        value: Edition.VERAO_2024
      },
      {
        label: 'E-Sports 2024',
        value: Edition.ESPORTS_2024
      },
      {
        label: 'JF 2025',
        value: Edition.JOIA_2025
      },
      {
        label: 'Verão 2025',
        value: Edition.VERAO_2025
      },
      {
        label: 'E-Sports 2025',
        value: Edition.ESPORTS_2025
      }
    ],
    IM: [
      {
        label: 'IM 24 Serie A',
        value: Edition.IM_2024_A
      },
      {
        label: 'IM 24 Serie B',
        value: Edition.IM_2024_B
      },
      {
        label: 'IM 25 Serie A',
        value: Edition.IM_2025_A
      },
      {
        label: 'IM 25 Serie B',
        value: Edition.IM_2025_B
      }
    ]
  }
};
